import CodeMirror from 'codemirror';

// Get the existing CSS mode
const cssMode = CodeMirror.getMode({}, 'css');

// Override the token function of CSS mode
function token(stream, state) {
  if (stream.match('{{', false)) {
    // Consume the opening {{
    stream.eatWhile('{');
    state.inTag = true;
    return 'tag';
  }

  if (state.inTag) {
    // If inside a tag, consume the stream until the closing }}
    if (stream.skipTo('}}')) {
      // Consume the closing }}
      stream.eatWhile('}');
      state.inTag = false;
    } else {
      stream.skipToEnd();
    }
    return 'tag';
  }

  return cssMode.token(stream, state.cssState);
}

// Define the new 'custom-css' mode
CodeMirror.defineMode('custom-css', function() {
  return {
    token: token,
    startState: function() {
      return {
        inTag: false,
        cssState: CodeMirror.startState(cssMode),
      };
    },
    copyState: function(state) {
      return {
        inTag: state.inTag,
        cssState: CodeMirror.copyState(cssMode, state.cssState),
      };
    },
  };
});

// Set the new 'custom-css' mode as the default mode for CSS files
CodeMirror.defineMIME('text/css', 'custom-css');
