<!-- eslint-disable no-useless-escape -->
<script>

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/html-hint';
import 'codemirror/addon/display/autorefresh.js';
import './custom-css-mode.js';
import './custom-js-mode.js';
import debounce from 'lodash.debounce';

import CodeMirror from 'codemirror';

import Variables from "@/components/personalizations/variables";

export default {
  data() {
		return {
      cssCode: '',
      jsCode: '',
      viewport: 'desktop',
      cssCodeMirror: null,
      jsCodeMirror: null,
      showVariables:false,
      variables:[],
      groups: [],
      indexTab:0,
      selectedText:'',
      buttonPosition: { top: 0, left: 0 },
      reservedVars:[
        "gs_variantId",
        "gs_experienceId",
        "gs_personalizationId",
        "#recommendations items",
        "/recommendations",
        "gs_recoStrategy",
        "gs_recoCount",
        "gs_recoStrategyAffinity",
        "gs_recommendations",
        "gs_recoStrategyCustom"
      ],
    }
  },
  components:{Variables},
 name: 'CustomJsBuilder',
  props:{
    items: Array,
    affinity_Types: Array,
    categories: Array,
    pageType: String
  },
  computed:{
    showConvertButton : function() {
      if(this.selectedText && this.selectedText!='' && this.selectedText.trim().length > 2 && this.reservedVars.filter(v=>v.toUpperCase() == this.selectedText.trim().toUpperCase()).length == 0){
        return true;
      }
      
      return false
    }
  },
 mounted(){
  this.initializeCodeMirror();

  const vm = this;
  document.body.addEventListener('click', function (evt) {

    if (evt.target.className.includes('cm-variables')) {
        
      const name =  evt.target.textContent.trim();
      if(vm.reservedVars.filter(r=> r.toUpperCase() == name.toUpperCase()).length ==0){
        vm.indexTab = 0;
        if(vm.variables.filter(v=>v.name.toUpperCase() == name.toUpperCase()).length==0){
          let variable = {
            name,
            options:[],
            type:null,
          }
          vm.$refs.variablesComponent.onNewVariable(variable)
        }else{
          vm.$refs.variablesComponent.onVariableSettingClicked(vm.variables.filter(v=>v.name == name)[0])
        }
      }
    }
  }, false);
 },
 methods:{
  
  initializeCodeMirror() {
        
      this.cssCodeMirror = CodeMirror.fromTextArea(this.$refs.cssTextArea, {
        mode:'text/css',
        theme: 'material',
        lineNumbers: true,
        lineWrapping: true,
        value: this.cssCode,
        viewportMargin: Infinity,
        autorefresh: true,
        tabSize: 2, 
        indentUnit: 2,
      });
      this.cssCodeMirror.refresh();

      this.jsCodeMirror = CodeMirror.fromTextArea(this.$refs.jsTextArea, {
        mode: 'text/javascript',
        theme: 'material',
        lineNumbers: true,
        lineWrapping: true,
        value: this.jsCode,
        viewportMargin: Infinity,
        autorefresh: true,
        tabSize: 2, 
        indentUnit: 2,
        indentWithTabs: false
      });
      this.jsCodeMirror.refresh();

      this.cssCodeMirror.on('change', (cm) => this.updatePreview('css',cm));
      this.jsCodeMirror.on('change', (cm) => this.updatePreview('js',cm));

      this.cssCodeMirror.on('keydown', (cm, event)=> this.onEditorKeydown(cm,event));
      this.jsCodeMirror.on('keydown', (cm, event)=> this.onEditorKeydown(cm,event));

      this.cssCodeMirror.on('cursorActivity', this.debouncedHandleCursorActivityCss);
      this.jsCodeMirror.on('cursorActivity', this.debouncedHandleCursorActivityJs);
    },
    
    debouncedHandleCursorActivityCss: debounce(function() {
      this.handleCursorActivity('css');
    }, 300),
    debouncedHandleCursorActivityJs: debounce(function() {
      this.handleCursorActivity('js');
    }, 300),

    handleCursorActivity(type) {

      if(!type)
        return;
      let editorPos;
      let cursorPos;
      if(type == 'css'){
        this.selectedText = this.cssCodeMirror.getSelection();
        editorPos = this.cssCodeMirror.getWrapperElement().getBoundingClientRect();
        cursorPos = this.cssCodeMirror.cursorCoords(true, 'page');
      }if(type == 'js'){
        this.selectedText = this.jsCodeMirror.getSelection();
        editorPos = this.jsCodeMirror.getWrapperElement().getBoundingClientRect();
        cursorPos = this.jsCodeMirror.cursorCoords(true, 'page');
      }
      
      if(this.reservedVars.filter(r=> r == this.selectedText).length > 0 || this.variables.filter(v=> v.name == this.selectedText).length > 0){
        this.selectedText = null;
        return;
      }

      const buttonTop = cursorPos.top - editorPos.top - 10; 
      const buttonLeft = cursorPos.left - editorPos.left - 10; 

      this.buttonPosition = {
        top: `${buttonTop}`,
        left: `${buttonLeft}`,
      };
    },
    onEditorKeydown(cm, event){
      var cursor = cm.getCursor();
      var lineContent = cm.getLine(cursor.line);
      var currentChar = lineContent.charAt(cursor.ch - 1);

      if (event.key === '{' && currentChar === '{') {
        cm.replaceRange('{}}', cursor, cursor);
        cm.setCursor(cursor.line, cursor.ch + 1);

        event.preventDefault(); 
      }
    },
    getCaseInsensitiveIndex(text, searchText) {
      const searchRegex = new RegExp(searchText, 'i');
      const match = text.match(searchRegex);
      return match ? match.index : -1;
    },
    isEndRecoInserted(text, startPos) {
      const endRecoText = '{{\/recommendations}}';
      const endRecoIndex = text.indexOf(endRecoText, startPos.ch);
      return endRecoIndex !== -1;
    },
    parsePreview(text){
      for (let i = 0; i < this.variables.length; i++) {
        let { name, value,type } = this.variables[i];
        
        if(type.id == "product_property_catalog"){
          if(this.variables[i].value?.column_name == 'product_category'){
            value = this.categories || [];
          }else if(this.variables[i].value){
            value = this.variables[i].value.values || [];
          }else {
            value = [];
          }
        }
        
        const variableRegex = new RegExp('\\{{' + name.trim() + '}}', 'g');
        
        text = text.replace(variableRegex, value);
      }
      
      return text.replace(/\{{.*?}}/g, ' ');
    },
    markText(cm) {
      cm.operation(() => {
        cm.getAllMarks().forEach((mark) => mark.clear());

        const content = cm.getValue();
        const pattern = /\{{([^}]*)}}/g;
        let match;
      
        while ((match = pattern.exec(content)) !== null) {
          const startPos = match.index + 2;
          const endPos = pattern.lastIndex - 2;
          
          let cla = content.substring(startPos, endPos)?.toUpperCase() == 'GO_RECO' || content.substring(startPos, endPos)?.toUpperCase() == 'END_GO_RECO' ? 'cm-reco' : 'cm-variables' ;
          cm.markText(
            cm.posFromIndex(startPos),
            cm.posFromIndex(endPos),
            { className: cla }
          );
        }
      });
    },
    updatePreview(type,cm) {
      switch(type){
        case 'css':
          this.cssCode = this.cssCodeMirror.getValue();
          break;
        case 'js':
          this.jsCode = this.jsCodeMirror.getValue();
          break;
      }
      if(cm)
        this.markText(cm);
    },
    
    getTemplatesValues(){
      return {
        css: this.cssCode,
        js: this.jsCode,
        type: 'js',
        variables: this.$refs.variablesComponent.getVariables(),
        groups: this.$refs.variablesComponent.getGroups()
      }
    },
    setTemplateValue(values){
      this.cssCode = values.css || '';
      this.jsCode = values.js || '';
      
      this.cssCodeMirror.setValue(this.cssCode);
      this.jsCodeMirror.setValue(this.jsCode);
      
      this.$refs.variablesComponent.setVariables(values.variables || [])
      this.$refs.variablesComponent.setGroups(values.groups || [])
      this.updateVariables(values.variables || []);
      this.updateGroups(values.groups || []);
    },
    updateVariables(variables){
      this.variables = variables;
    },
    updateGroups(groups){
      this.groups = groups;
    },
    onTabClicked(type){
      this.selectedText = '';
      switch(type){
        case 'css':
        setTimeout(()=>{
            this.cssCodeMirror.focus();
            this.cssCodeMirror.refresh();
          },100)
          break;
        case 'js':
        setTimeout(()=>{
            this.jsCodeMirror.focus();
            this.jsCodeMirror.refresh();
          },100)
          break;
      }
    },
    onConvertToVariableClicked(){
      this.indexTab = 0;
      let variable = {
          name: this.selectedText,
          options:[]
        }
        this.$refs.variablesComponent.onNewVariable(variable)
    }
  }
}
</script>

<template>
  
  <div class="row">
    <div class="col-12">
      <b-tabs v-model="indexTab" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
        <b-tab active>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              Variables
            </span>
            <span class="d-none d-sm-inline-block"> <i class="mdi mdi-code-json"></i> VARS</span>
          </template>
          <div class="code-editor">
            <Variables ref="variablesComponent" @updateVariables="updateVariables" @updateGroups="updateGroups" :affinity_Types="affinity_Types" :pageType="pageType"/>
          </div>
        </b-tab>
        <b-tab v-on:click="onTabClicked('css')">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              Css
            </span>
            <span class="d-none d-sm-inline-block"> <i class="mdi mdi-brush"></i> CSS</span>
          </template>
          <div class="code-editor">
            <textarea ref="cssTextArea"></textarea>
          </div>
          <div class="button-container" v-if="showConvertButton" :style="{ top: buttonPosition.top + 'px', left: buttonPosition.left + 'px' }">
              <button class="btn btn-link btn-md" @click="onConvertToVariableClicked">{{$t('personalizations.experience_variables_convert')}}</button>
          </div>
        </b-tab>
        <b-tab v-on:click="onTabClicked('js')">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              JS
            </span>
            <span class="d-none d-sm-inline-block"> <i class="mdi mdi-language-javascript"></i> JS</span>
          </template>
          <div class="code-editor">
            <textarea ref="jsTextArea"></textarea>
          </div>
          <div class="button-container" v-if="showConvertButton" :style="{ top: buttonPosition.top + 'px', left: buttonPosition.left + 'px' }">
              <button class="btn btn-link btn-md" @click="onConvertToVariableClicked">{{$t('personalizations.experience_variables_convert')}}</button>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
    title="Variable"
    v-model="showVariables"
    hide-footer>
    <div>
       <span v-for="v in variables" :key="v.id">
          {{v}}
       </span> 
    </div>
  </b-modal>
  </div>
</template>

<style>
.code-editor {
  width: 100%;
  height: 600px;
  margin-bottom: 10px;
}

.preview {
  width: 100%;
  height: 600px;
}

.preview-iframe{
  width: 100%;
  background-color: #f8f8fb;
  height: 600px;
  display: block;
  margin: auto;
}

.CodeMirror{
  height: 600px !important;
}

.interact {
  cursor: pointer;
}

.cm-variables{
  background-color: rgba(241,180,76,.25)!important;
  cursor: pointer;
}

.cm-reco{
  background-color: rgba(195, 191, 206, 0.359)!important;
}

.button-container {
  position: absolute;
  background-color: white;
  padding: 5px;
  border: 1px solid black;
}
</style>

