<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import CustomWebBuilder from "@/components/personalizations/customWebBuilder";
import CustomJsBuilder from "@/components/personalizations/customJSBuilder";
import EmailBlockBuilder from "@/components/personalizations/emailBlockBuilder";
import EmailBuilder from "@/components/journeys/configs/email-unlayer-builder.vue";

import {
	templateMethods,
  projectMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "gopersonal Templates",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    CustomWebBuilder,
    CustomJsBuilder,
    EmailBlockBuilder,
    EmailBuilder
  },
  data() {
	return {
		title: this.$t('events.title'),
		templates: [],
    confirmDelete:false,
		currentPage:1,
		perPage:10,
		rows:0,
    isLoading:false,
    templateId:null,
    pageLoaded:false,
    empty_config:{
        "title" : this.$t('templates.empty_list'),
        "subtitle" : this.$t('templates.empty_list_subtitle'),
        "buttonText" : this.$t('templates.new_template'),
        icon: "fa-cubes"
      },
      empty_list_config:{
          "title" : this.$t("templates.empty_search_title"),
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      query:'',
      showTemplate: false,
      selectedTemplate: null,
      items: [],
      affinity_Types: [],
      categories:[],
      pageType: null,
      variant: null,
      viewport: 'desktop',
      mode: 'design'
      
    }
  },
  
  mounted() {
    this.loadTemplates();
    this.loadItems();
  },
  created(){
		this.debounceTemplates = _debounce(this.searchTemplates.bind(this), 1000);
	},
  methods: {
	...templateMethods,
  ...projectMethods,

  onNewTemplate(){
    
  },
  loadItems(){
        
				const params = {
					q: `whereObj={"project":"${localStorage.getItem('current_project')}", "data.active":1}&limit=50`
				}

        this.getItems(params).then((res)=>{
					this.items = res.data;
        }).catch(()=>{
					this.$notify({ type: 'error', text: this.$t('items.list_get_error'),title:  this.$t('items.title') });
				}).finally(()=>{
					
				})
		},
  onRemoveTemplateClicked(templateId){
    this.confirmDelete = true;
    this.templateId = templateId
  },

  onConfirmRemoveTemplateClicked(){
    let loader = this.$loading.show();
    this.deleteTemplate(this.templateId).then(()=>{
        this.$notify({ type: 'success', text: this.$t('templates.delete_template_success'),title: "Templates" });
        this.currentPage=1;
        this.loadTemplates();
    }).catch(()=>{
      this.$notify({ type: 'error', text: this.$t('templates.delete_template_error'),title: "Templates" });		
    }).finally(()=>{
        this.isLoading = false;
        loader.hide();
        this.templateId=null;
    })
  },
  loadTemplates(){

    let loader = this.$loading.show();
    const params={
				q: `where[scratch]=false&where[lang]=es&sortObj={"_id":-1}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
			}
      this.isLoading = true;
    
      this.getTemplatesStore(params).then((res)=>{
        this.templates = res.data;
        this.rows= res.totalCount;
        this.pageLoaded = true;
        
      }).catch((ex)=>{
        // eslint-disable-next-line no-console
        console.log(ex);
      }).finally(()=>{
        loader.hide()
      })
  },

  onTemplatesListPageClicked(){
    this.loadTemplates();
  },
  onEditTemplateClicked(data){
    // eslint-disable-next-line no-console
    console.log(data);
    this.selectedTemplate = data;
    this.showTemplate = true;

    const loader = this.$loading.show();
    setTimeout(()=>{
            if(this.selectedTemplate?.type== 'personalization'){
              if(this.selectedTemplate.subtype=='custom_code'){
              this.$refs.customJsBuilder.setTemplateValue(data.value);  
              }else{
                this.$refs.customWebBuilder.setTemplateValue(data.value);
              }
            }else if(this.selectedTemplate?.type == 'email'){
              if(this.selectedTemplate.value.design){
                setTimeout(()=>{
                  this.$refs.dragEmailBuilder.setTemplateValue(this.selectedTemplate.value || {});
                },1000)
              }else{
                setTimeout(()=>{
                  this.$refs.customWebBuilder.setTemplateValue(this.selectedTemplate.value || {});
                },1000)
              }
            }
            else{
              this.$refs.emailBlockBuilder.setTemplateValue(data.value, data._id);  
            }
            
            loader.hide();
        },2000)
  },

    searchTemplates(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("templates.empty_search_text").replaceAll('#', this.query);

			if(query){
				this.currentPage = 1;
						const params={
							q: `where[scratch]=false&where[lang]=es&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
						}
						this.getTemplatesStore(params).then((res)=>{
							this.templates = res.data;
              this.rows= res.totalCount;
            }).catch(()=>{
							this.$notify({ type: 'error', text: this.$t('templates.get_templates_error'),title: "Templates" });
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
			}else{
				this.isLoading = false;
				loader.hide();
				this.currentPage = 1;
				this.loadTemplates();
			}
		},

    getTemplateType(type){
      switch(type){
        case 'web': return this.$t("personalizations.type_web"); 
        case 'recommendations': return this.$t("personalizations.type_reco"); 
        case 'custom_code': return this.$t("personalizations.type_custom_code"); 
        case 'pop_up': return this.$t("personalizations.type_pop_up"); 
        case 'notifications': return this.$t("personalizations.type_notifications");
      }
      return type;
    },
    onResetSearchClicked(){
      this.query = '';
      this.loadTemplates();
    },
    async onConfirmTemplateClicked(exit=false){
      
      if(this.selectedTemplate.type=='personalization'){
        if(this.selectedTemplate.subtype == 'custom_code'){
          this.selectedTemplate.value = this.$refs.customJsBuilder.getTemplatesValues();
        }else{
          this.selectedTemplate.value = this.$refs.customWebBuilder.getTemplatesValues();
        }
      }else if(this.selectedTemplate?.type == 'email'){
        if(this.selectedTemplate.value.design){
          // eslint-disable-next-line no-console
          console.log(this.selectedTemplate.value.design, this.$refs.dragEmailBuilder.getTemplatesValues());
          this.selectedTemplate.value = await this.$refs.dragEmailBuilder.getTemplatesValues();
        }else{
          this.selectedTemplate.value = this.$refs.customWebBuilder.getTemplatesValues();
        }
      }else if(this.selectedTemplate.type=='email_block'){
        this.selectedTemplate.value = this.$refs.emailBlockBuilder.getTemplatesValues();
      }
      
      this.updateTemplateStore(this.selectedTemplate).then(()=>{
        if(exit){
          this.showTemplate =false;
          this.currentPage = 1;
          this.loadTemplates();
        }
      }).catch((ex)=>{
        
        // eslint-disable-next-line no-console
        console.log('error',ex);
      })
    },
    setViewport(viewport){
      this.viewport= viewport;
      if(this.selectedTemplate.type=='email_block'){
        this.$refs.emailBlockBuilder.onResolutionChanged(viewport);
      }else{
        this.$refs.customWebBuilder.onResolutionChanged(viewport);
      }
      
    },
    setMode(mode){
      this.mode = mode;
      if(this.selectedTemplate.type=='email_block'){
        this.$refs.emailBlockBuilder.onSetMode(mode);
      }else{
        this.$refs.customWebBuilder.onSetMode(mode);
      }
      
    }
  },
  
};
</script>

<template>
	<Layout>
		<PageHeader title="gopersonal Templates" :items="[]" />
		<div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">  
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="search-box me-2 mb-0 d-inline-block">
                        <div class="position-relative">
                            <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('common.search')"
                            @input="debounceTemplates($event.target.value)"
                            v-model="query"
                            />
                            <i class="bx bx-search-alt search-icon"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="text-sm-end">
                          <button
                            type="button"
                            class="btn btn-success btn-rounded mb-0 me-0" @click="onNewTemplate">
                              <i class="mdi mdi-plus me-1"></i> {{$t('templates.new_template')}}
                          </button>
                        </div>
                    </div>
                </div>
                <EmptyList :config="empty_list_config" v-if="!isLoading && templates.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
                <EmptyList :config="empty_config" v-if=" !isLoading && templates.length == 0 && query == ''" @onButtonClicked="onNewTemplate" class="mt-3"/>
            </div>
            <div class="table-responsive mb-0" v-if="pageLoaded && templates.length > 0">
              <table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">{{$t('templates.name')}}</th>
                    <th scope="col">{{$t('templates.type')}}</th>
                    <th scope="col">{{$t('templates.subtype')}}</th>
                    <th scope="col">{{$t('templates.last_modification')}}</th>
                    <th scope="col" class="text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="template in templates" :key="template._id">
                    <td>
                      <h5 class="font-size-14 mb-1" v-on:click="onEditTemplateClicked(template)">
                        <a href="#" class="text-dark">{{template.name}}</a>
                      </h5>
                    </td>
								<td>{{ getTemplateType(template.type)}}</td>
                <td>{{ getTemplateType(template.subtype)}}</td>
                <td>{{template.updatedAt ? `${new Date(template.updatedAt).toLocaleDateString()} ${new Date(template.updatedAt).toLocaleTimeString()}` : ''}}</td>
								<td class="text-end">
                  <i class="fasfa-edit text-success me-2 interact" v-on:click="onEditTemplateClicked(template)"></i>
                  <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveTemplateClicked(template._id)"></i>
								</td>
							</tr>
                </tbody>
              </table>
            </div>
        </div>
        <div class="row mb-3" v-if="rows > perPage">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      @input="onTemplatesListPageClicked"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
      </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveTemplateClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{ $t('templates.remove_template') }}</p>
        </b-modal>
        <b-modal
          v-model="showTemplate"
					id="modal-xl"
          size="xl"
					title-class="font-18"
          no-close-on-backdrop 
          scrollable
          title="template"
          @ok.prevent="onConfirmTemplateClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <template #modal-header>
            
            <div class="left-content">
              <input class="form-control" v-model="selectedTemplate.name" style="font-weight: bold;     font-weight: bold; width: 185px;"/>
              <div class="btn-group" v-if="selectedTemplate?.subtype!= 'API' && selectedTemplate?.subtype != 'custom_code'">
                <button type="button" :class="viewport =='desktop' ?'btn btn-primary' : 'btn btn-outline-primary'" v-if="false" @click="setMode('preview')"  >
                  {{$t('personalizations.experience_variant_mode_preview')}}
                </button>
                <button type="button" :class="mode =='design' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setMode('design')" >
                  {{$t('personalizations.experience_variant_mode_design')}}
                </button>
                <button type="button" :class="mode =='code' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setMode('code')" >
                  {{$t('personalizations.experience_variant_mode_advanced')}}
                </button>
              </div>
            </div>
            <div class="center-content" v-if="mode=='design'">
              <div class="btn-group viewport-controls" v-if="selectedTemplate?.subtype!= 'API' && selectedTemplate?.subtype != 'custom_code'">
                <button type="button" :class="viewport =='desktop' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('desktop')"  >
                  <i class="mdi mdi-desktop-mac"></i>
                </button>
                <button type="button" :class="viewport =='tablet' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('tablet')">
                  <i class="mdi mdi-tablet-ipad"></i>
                </button>
                <button type="button" :class="viewport =='mobile' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('mobile')">
                  <i class="mdi mdi-cellphone-iphone"></i>
                </button>
              </div>
            </div>
          </template>

          <template #modal-footer>
            
                <b-button variant="secondary" class="me-2" @click="showTemplate = false">{{$t('common.cancel')}}</b-button>
                <button class="btn btn-outline-primary me-2" @click="onConfirmTemplateClicked()">{{$t('common.save')}}</button>
                <b-button variant="primary" class="btn btn-primary" @click.prevent="onConfirmTemplateClicked(true)">{{  $t('common.confirm')  }}</b-button>
              
          </template>  
          <CustomWebBuilder ref="customWebBuilder" :items="items" v-if="selectedTemplate?.type=='personalization' && selectedTemplate?.subtype != 'custom_code'" :affinity_Types="affinity_Types" :categories="categories" :variant="variant" :pageType="pageType"/>
          <CustomJsBuilder ref="customJsBuilder" v-if="selectedTemplate?.type=='personalization' && selectedTemplate?.subtype == 'custom_code'" :categories="categories" :items="items" :affinity_Types="affinity_Types" :pageType="pageType" />
          <EmailBlockBuilder ref="emailBlockBuilder" :items="items" v-if="selectedTemplate?.type=='email_block' && selectedTemplate?.subtype != 'custom_code'" :affinity_Types="affinity_Types" :categories="categories" :reco_email="true"/>
          <EmailBuilder v-if="selectedTemplate?.type=='email' && selectedTemplate?.value?.design" ref="dragEmailBuilder"   />
      </b-modal>
    </div>
  </Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}

.left-content {
    display: flex;
    align-items: center;
    gap: 10px; /* Optional: Adjust spacing between input and buttons */
}

.center-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

</style>